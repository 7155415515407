.footer {
    position: relative;
    color: white;
    width: 100%;
    background: #1B3F95;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: small;
    padding: 1rem 2rem;
    margin-top: 50px;
    height: 3.75rem;
  }
  
  .footer div {
    margin-bottom: 0.5rem;
  }
  
  .footer span {
    color: white;
  }
  
  .footerlink {
    color: yellow;
    font-weight: 400;
    text-decoration: none;
  }
   
  @media screen and (max-width: 400px) {
    .footer {
      margin-top: 100px;
      font-size: smaller;
    }
    .footTxt{
      margin-left: -60px;
    }
    
  }
  