.search {
  width: 220px;
  position: relative;
  display: flex;

}

.searchTerm {
  width: 200px;
  border: 3px solid #1B3F95;
  border-right: none;
  padding: 5px;
  height: 20px;
  border-radius: 5px 0 0 5px;
  outline: none;
  color: #1B3F95;
}

.searchTerm:focus{
  color: red;
}

.searchButton {
  width: 40px;
  height: 36px;
  border: 1px solid #1B3F95;
  background: #1B3F95;
  text-align: center;
  color: #fff;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  font-size: 20px;
}

.wrap{
  width: 10%;
  position: absolute;
  margin-top: 45px;
  margin-left: 1350px;
  transform: translate(-50%, -50%);
}

.table-container {
  width: 100%;
}

.header{
  margin-top: 100px;
}
.title {
  padding: 10px 50px;
  color: darkgrey;
}

.profile {
  margin-left: 390px;
  margin-top: -8px;
  width: 130px;
  height: 150px;
}
/* 1st table*/
.table1{
  margin: 20px auto;
  margin-left: 350px;
  border-collapse: collapse;
  /* border: 1px solid black;   */
  font-size: small;
}
.th1clz{
  padding: 8px;
  text-align: left;
  border: 1px solid black;

}
.td1clz{
  width: 200px;
  padding: 8px;
  text-align: left;
  border: 1px solid black;

}

/*2nd table*/
.table2{
 display: none; 
}

/*2nd main table*/
.tableDetails {
  margin: 0 auto;
  width: 80%;
  margin-top: 100px;
}

/* Details Section */
.detailsClz01, .detailsClz02, .detailsClz03, .detailsClz04, .detailsClz05, .detailsClz06, .detailsClz07 {
  width: 400px;
  height: 350px;
  border: 1px solid #161515;
  margin: 10px;
  float: left;
}

.heading {
  padding: 10px 20px 50px 30px;
}

/*data boxes system details*/
.ItemCode1{
  font-weight: bold;
  color: red;
  margin-top: -100px;
  margin-left: 280px;
}
.img1 {
  display: block;
  margin-top: 30px;  
  margin-left: 30px;
  width: 100px;
  height: 100px;
}
.table21{
  width: 200px;
  margin-left: 160px;
  margin-top: -120px;
}
.thclass1{
  width: 90px;
  text-align: left;
}
.tdclass2{
  width: 120px;
}
.serialCode1 {
  font-weight: bold;
  color: green;
  margin-left: -140px;
}

/*data boxes Keyboard details*/
.ItemCode2{
  font-weight: bold;
  color: red;
  margin-top: -100px;
  margin-left: 280px;
}
.img2 {
  display: block;
  width: 100px;
  height: 100px;
  margin-left: 30px;
  margin-top: 30px;
}
.table22{
  width: 200px;
  margin-left: 160px;
  margin-top: -100px;
}
.thclass2{
  width: 90px;
  text-align: left;
}
.tdclass2{
  width: 120px;
}
.serialCode2 {
  font-weight: bold;
  color: green;
  margin-left: -140px;
  margin-top: 130px;
}

/*data boxes Screen details*/
.table23{
  width: 200px;
}
.ItemCode3{
  font-weight: bold;
  color: red;
  margin-top: -200px;
  margin-left: 280px;
}
.heading3 {
  margin-top: -40px;
  margin-left:20px ;
}
.buttons{
  margin-top: 60px;
}
.thclass23{
  width: 90px;
  text-align: left;
 }
.tdclass23{
  width: 120px;
  margin-left: 10px;
  text-align: left;
 }
.img3 {
  width: 100px;
  height: 100px;
  margin-left: 50px;
  margin-top: 50px;
}
.screen1{
  margin-top: 70px;
  margin-left: 150px;
}
.serialCode3 {
  font-weight: bold;
  color: green;
  margin-left: -140px;
  margin-top: 70px;
}

/*data boxes Mouse details*/
.ItemCode4{
  font-weight: bold;
  color: red;
  margin-top: -100px;
  margin-left: 280px;
}
.img4 {
  display: block;
  width: 100px;
  height: 100px;
  margin-left: 30px;
  margin-top: 30px;
}
.table24{
  width: 200px;
  margin-left: 160px;
  margin-top: -80px;
}
.thclass24{
  width: 90px;
  text-align: left;
 }
.tdclass24{
  width: 120px;
  margin-left: 10px;
 }
.serialCode4 {
  font-weight: bold;
  color: green;
  margin-left: -120px;
  margin-top: 120px;
}


/*data boxes Laptop details*/
.ItemCode5{
  font-weight: bold;
  color: red;
  margin-top: -100px;
  margin-left: 280px;
}
.img5 {
  display: block;
  width: 100px;
  height: 100px;
  margin-left: 30px;
  margin-top: 60px;
}
.table25{
  width: 250px;
  margin-left: 140px;
  margin-top: -120px;
}
.thclass25{
  width: 90px;
  text-align: left;
 }
.tdclass25{
  width: 120px;
  margin-left: 10px;
  text-align: left;
 }
.serialCode5 {
  font-weight: bold;
  color: green;
  margin-left: -120px;
  margin-top: 20px;
}

/*data boxes UPS details*/
.ItemCode6{
  font-weight: bold;
  color: red;
  margin-top: -100px;
  margin-left: 280px;
}
.img6 {
  display: block;
  width: 100px;
  height: 100px;
  margin-left: 30px;
  margin-top: 50px;
}
.table26{
  width: 250px;
  margin-left: 140px;
  margin-top: -100px;
}
.thclass26{
  width: 90px;
  text-align: left;
 }
.tdclass26{
  width: 120px;
  margin-left: 10px;
  text-align: left;
 }
.serialCode6 {
  font-weight: bold;
  color: green;
  margin-left: -120px;
  margin-top: 150px;
}
 
 
/*data boxes Priter details*/
.ItemCode7{
  font-weight: bold;
  color: red;
  margin-top: -100px;
  margin-left: 280px;
}
.img7 {
  display: block;
  width: 100px;
  height: 100px;
  margin-left: 30px;
  margin-top: 40px;
}
.table27{
  width: 250px;
  margin-left: 140px;
  margin-top: -80px;
}
.thclass27{
  width: 90px;
  text-align: left;
 }
.tdclass27{
  width: 120px;
  margin-left: 10px;
  text-align: left;
 }
.serialCode7 {
  font-weight: bold;
  color: green;
  margin-left: -120px;
  margin-top: 90px;
}


 
.input-field {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  box-sizing: border-box;
  margin-left: -500px;
  margin-top: 30px;
  width: 200px;
}

.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
}

.tab button:hover {
  background-color: #ddd;
}

.tab button.active {
  background-color: #ccc;
}

.tabcontent {
  display: none;
  padding: 6px 12px;
  border-top: none;
}

.tabcontent.show {
  display: block;
}

/* Media Queries for Responsiveness */
@media screen and (max-width: 1000px) {



  .title {
    width: 100%;
    margin-top: 10px;
    color: darkgrey;
  }
  
  .wrap{
    margin-top: 50px;
    margin-left: -90px;
  }

  .profile {
    width: 120px;
    height: 130px;
    margin-top: 20px;
    margin-left: 140px;  
  }

  .table1{
  display: none;
  }

  .table2{
    display: block;
    margin-left: -38px;
    margin-top: 190px;
    border-collapse: collapse;
    font-size: small;
  } 
  .th2clz{
    padding: 8px;
    text-align: left;
    border-collapse:  separate;
    border: 1px solid black;
  }
  .td2clz{
    width: 200px;
    padding: 8px;
    text-align: left;
    border-collapse: collapse;
    border: 1px solid black;
  }
 
  

  .detailsClz01{
    margin-left: auto;
    margin-right: auto;
    float: none;
    width: 360px;
    border: 1px solid black;
    }

  .detailsClz02{
  margin-left: auto;
  margin-right: auto;
  float: none;
  width: 360px;
  height: 220px;
  border: 1px solid black;
  }
  .detailsClz03{
    margin-left: auto;
    margin-right: auto;
    float: none;
    width: 360px;
    height: 300px;
    border: 1px solid black;

  }
  .detailsClz04{
    margin-left: auto;
    margin-right: auto;
    float: none;
    width: 360px;
    height: 220px;
    border: 1px solid black;
  }
  .detailsClz05{
    margin-left: auto;
    margin-right: auto;
    float: none;
    width: 360px;
    height: 300px;
    border: 1px solid black;
  }
  .detailsClz06{
    margin-left: auto;
    margin-right: auto;
    float: none;
    width: 360px;
    height: 220px;
    border: 1px solid black;
  }
  .detailsClz07{
    margin-left: auto;
    margin-right: auto;
    float: none;
    width: 360px;
    height: 220px;
    border: 1px solid black;
  }



/*System  details*/
.ItemCode1{
  font-weight: bold;
  color: red;
  margin-top: -100px;
  margin-left: 240px;
}
.img1 {
  display: block;
  margin-top: 30px;  
  margin-left: 30px;
  width: 80px;
  height: 80px;
}
.table21{
  width: 200px;
  margin-left: 140px;
  margin-top: -100px;
}

.thclass1{
  width: 90px;
  text-align: left;
}
.tdclass2{
  width: 120px;
}
.serialCode1 {
  font-weight: bold;
  color: green;
  margin-left: -120px;
}

/*Keyboard  details*/
.ItemCode2{
  font-weight: bold;
  color: red;
  margin-top: -100px;
  margin-left: 240px;
}
.img2 {
  display: block;
  margin-top: 30px;  
  margin-left: 30px;
  width: 80px;
  height: 80px;
}
.table22{
  width: 200px;
  height: 200px;
  margin-left: 140px;
  margin-top: -80px;
}
.thclass2{
  width: 90px;
  text-align: left;
}
.tdclass2{
  width: 120px;
}
.serialCode2 {
  font-weight: bold;
  color: green;
  margin-left: -120px;
  margin-top: 10px;
}

/*data boxes Screen details*/
.table23{
  width: 200px;
}
.ItemCode3{
  font-weight: bold;
  color: red;
  margin-top: -220px;
  margin-left: 230px;
}
.heading3 {
  margin-top: -60px;
  margin-left: 30px ;
}
.buttons{
  margin-top: 80px;
  margin-left: 10px;
  border: 1px solid transparent;
}
.img3 {
  width: 100px;
  height: 100px;
  margin-left: -150px;
  margin-top: 60px;
}
.screen1{
  margin-top: 70px;
  margin-left: 150px;
}
.serialCode3 {
  font-weight: bold;
  color: green;
  margin-left: -140px;
  margin-top: 20px;
}

/*data boxes Mouse details*/
.ItemCode4{
  font-weight: bold;
  color: red;
  margin-top: -100px;
  margin-left: 240px;
}
.img4 {
  display: block;
  width: 100px;
  height: 100px;
  margin-left: 30px;
  margin-top: 30px;
}
.table24{
  width: 200px;
  margin-left: 160px;
  margin-top: -80px;
}
.thclass24{
  width: 90px;
  text-align: left;
 }
.tdclass24{
  width: 120px;
  margin-left: 10px;
 }
.serialCode4 {
  font-weight: bold;
  color: green;
  margin-left: -120px;
  margin-top: 20px;
}

/*data boxes Laptop details*/
.ItemCode5{
  font-weight: bold;
  color: red;
  margin-top: -100px;
  margin-left: 280px;
}
.img5 {
  display: block;
  width: 100px;
  height: 100px;
  margin-left: 10px;
  margin-top: 50px;
}
.table25{
  width: 250px;
  margin-left: 120px;
  margin-top: -120px;
}
.thclass25{
  width: 90px;
  text-align: left;
 }
.tdclass25{
  width: 120px;
  margin-left: 10px;
  text-align: left;

 }
.serialCode5 {
  font-weight: bold;
  color: green;
  margin-left: -100px;
  margin-top: 10px;
}

/*data boxes UPS details*/
.ItemCode6{
  font-weight: bold;
  color: red;
  margin-top: -100px;
  margin-left: 280px;
}
.img6 {
  display: block;
  width: 100px;
  height: 100px;
  margin-left: 30px;
  margin-top: 60px;
}
.table26{
  width: 250px;
  margin-left: 140px;
  margin-top: -90px;
}
.thclass26{
  width: 90px;
  text-align: left;
 }
.tdclass26{
  width: 120px;
  margin-left: 10px;
  text-align: left;

 }
.serialCode6 {
  font-weight: bold;
  color: green;
  margin-left: -120px;
  margin-top: 50px;
}

/*data boxes Printer details*/
.ItemCode7{
  font-weight: bold;
  color: red;
  margin-top: -100px;
  margin-left: 280px;
}
.img7 {
  display: block;
  width: 100px;
  height: 100px;
  margin-left: 30px;
  margin-top: 60px;
}
.table27{
  width: 250px;
  margin-left: 140px;
  margin-top: -90px;
}
.thclass27{
  width: 90px;
  text-align: left;
 }
.tdclass27{
  width: 120px;
  margin-left: 10px;
  text-align: left;

 }
.serialCode7 {
  font-weight: bold;
  color: green;
  margin-left: -120px;
  margin-top: 50px;
}







  .styled-form {
    margin: 20px auto;
    max-width: 100%;
  }

  .form-group {
    flex-direction: column;
  }

  .input-field {
    margin-top: -20px;
    margin-left: -50px;
    width: 200px;
  }

  
  
}

@media screen and (max-width: 400px) {

  .table-container {
    width: 400px;
  }
  .title {
    width: 100%;
    margin-top: 10px;
    margin-left: -20px;
    color: darkgrey;
  }
  .wrap{
    margin-top: 10px;
    margin-left: 110px;
  }
  .profile {
    width: 120px;
    height: 130px;
    margin-top: 10px;
    margin-left: 110px;  
  }

  .table1{
  display: none;
  }

  .table2{
    display: block;
    margin-left: -68px;
    margin-top: 190px;
    border-collapse: collapse;
    font-size: small;
  } 
  .th2clz{
    padding: 8px;
    text-align: left;
    border-collapse:  separate;
    border: 1px solid black;
  }
  .td2clz{
    width: 200px;
    padding: 8px;
    text-align: left;
    border-collapse: collapse;
    border: 1px solid black;
  }
  .detailsClz01{
    margin-left: 5px;
    float: none;
    width: 350px;
    height: 320px;
    border: 1px solid black;
  }
  .detailsClz02{
    margin-left: 5px;
  float: none;
  width: 350px;
  height: 220px;
  border: 1px solid black;
  }
  .detailsClz03{
    margin-left: 5px;
    float: none;
    width: 350px;
    height: 250px;
    border: 1px solid black;

  }
  .detailsClz04{
    margin-left: 5px;
    float: none;
    width: 350px;
    height: 220px;
    border: 1px solid black;
  }
  .detailsClz05{
    margin-left: 5px;
    float: none;
    width: 350px;
    height: 300px;
    border: 1px solid black;
  }
  .detailsClz06{
    margin-left: 5px;
    float: none;
    width: 350px;
    height: 220px;
    border: 1px solid black;
  }
  .detailsClz07{
    margin-left: 5px;
    margin-top: -5px;
    float: none;
    width: 350px;
    height: 220px;
    border: 1px solid black;
  }
  
/*System  details*/
.ItemCode1{
  font-weight: bold;
  color: red;
  margin-top: -100px;
  margin-left: 240px;
  font-size: small;
}
.img1 {
  display: block;
  margin-top: 30px;  
  margin-left: 30px;
  width: 80px;
  height: 80px;
}
.table21{
  width: 200px;
  margin-left: 140px;
  margin-top: -100px;
  font-size: small;
}

.thclass1{
  width: 90px;
  text-align: left;
}
.tdclass2{
  width: 120px;
}
.serialCode1 {
  font-weight: bold;
  color: green;
  margin-left: -120px;
  font-size: small;
}

/*Keyboard  details*/
.ItemCode2{
  font-weight: bold;
  color: red;
  margin-top: -100px;
  margin-left: 240px;
  font-size: small;
}
.img2 {
  display: block;
  margin-top: 30px;  
  margin-left: 30px;
  width: 80px;
  height: 80px;
}
.table22{
  width: 200px;
  height: 200px;
  margin-left: 140px;
  margin-top: -80px;
  font-size: small;
}
.thclass2{
  width: 90px;
  text-align: left;
}
.tdclass2{
  width: 120px;
}
.serialCode2 {
  font-weight: bold;
  color: green;
  margin-left: -120px;
  margin-top: 30px;
  font-size: small;
}

/*data boxes Screen details*/
.table23{
  width: 200px;
  font-size: small;
}
.ItemCode3{
  font-weight: bold;
  color: red;
  margin-top: -160px;
  margin-left: 230px;
  font-size: small;
}
.heading3 {
  margin-top: 10px;
  margin-left: 150px ;
}
.buttons{
  margin-top: 10px;
  margin-left: 10px;
  width: 80px;
  border: 1px solid transparent;
}
.img3 {
  width: 100px;
  height: 100px;
  margin-left: -50px;
  margin-top: 10px;
}
.screen1{
  margin-top: 40px;
  margin-left: 150px;
}
.serialCode3 {
  font-weight: bold;
  color: green;
  margin-left: -140px;
  margin-top: 30px;
  font-size: small;
}

/*data boxes Mouse details*/
.ItemCode4{
  font-weight: bold;
  color: red;
  margin-top: -100px;
  margin-left: 240px;
  font-size: small;
}
.img4 {
  display: block;
  width: 100px;
  height: 100px;
  margin-left: 30px;
  margin-top: 30px;
}
.table24{
  width: 200px;
  margin-left: 160px;
  margin-top: -80px;
  font-size: small;
}
.thclass24{
  width: 90px;
  text-align: left;
 }
.tdclass24{
  width: 120px;
  margin-left: 10px;
 }
.serialCode4 {
  font-weight: bold;
  color: green;
  margin-left: -120px;
  margin-top: 20px;
  font-size: small;
}

/*data boxes Laptop details*/
.ItemCode5{
  font-weight: bold;
  color: red;
  margin-top: -100px;
  margin-left: 250px;
  font-size: small;
}
.img5 {
  display: block;
  width: 100px;
  height: 100px;
  margin-left: 10px;
  margin-top: 30px;
}
.table25{
  width: 250px;
  margin-left: 120px;
  margin-top: -100px;
  font-size: small;
}
.thclass25{
  width: 90px;
  text-align: left;
 }
.tdclass25{
  width: 120px;
  margin-left: 10px;
  text-align: left;

 }
.serialCode5 {
  font-weight: bold;
  color: green;
  margin-left: -100px;
  margin-top: 10px;
  font-size: small;
}

/*data boxes UPS details*/
.ItemCode6{
  font-weight: bold;
  color: red;
  margin-top: -100px;
  margin-left: 280px;
  font-size: small;
}
.img6 {
  display: block;
  width: 100px;
  height: 100px;
  margin-left: 30px;
  margin-top: 60px;
}
.table26{
  width: 250px;
  margin-left: 140px;
  margin-top: -90px;
  font-size: small;
}
.thclass26{
  width: 90px;
  text-align: left;
 }
.tdclass26{
  width: 120px;
  margin-left: 10px;
  text-align: left;

 }
.serialCode6 {
  font-weight: bold;
  color: green;
  margin-left: -120px;
  margin-top: 50px;
  font-size: small;
}

/*data boxes Printer details*/
.ItemCode7{
  font-weight: bold;
  color: red;
  margin-top: -100px;
  margin-left: 280px;
  font-size: small;
}
.img7 {
  display: block;
  width: 100px;
  height: 100px;
  margin-left: 30px;
  margin-top: 60px;
}
.table27{
  width: 250px;
  margin-left: 140px;
  margin-top: -90px;
  font-size: small;
}
.thclass27{
  width: 90px;
  text-align: left;
 }
.tdclass27{
  width: 120px;
  margin-left: 10px;
  text-align: left;

 }
.serialCode7 {
  font-weight: bold;
  color: green;
  margin-left: -120px;
  margin-top: 50px;
  font-size: small;
}

  .tab button {
    width: 100%;
    padding: 10px;
  }
  
  .styled-form {
    margin: 10px auto;
    padding: 15px;
  }

  .input-field {
    padding: 8px;
  }
}
