.headerr {
    position:relative;
    color: white;
    width: 100%;
    background: #efefef;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: small;
    padding: 1rem 2rem;
    height: 3.75rem;
  }
  
  .header div {
    margin-bottom: 0.5rem;
  }
  
  .header span {
    color: white;
  }
  
  .headerlink {
    color: yellow;
    font-weight: 400;
    text-decoration: none;
  }

  .logo{
    margin-left: -1650px;
  }

  @media screen and (max-width: 1000px) {
    .header {
      margin-top: 100px;
      font-size: smaller;
    }
    .logo{
      margin-left: -180px
    }
  }
   
  @media screen and (max-width: 400px) {
    .header {
      margin-top: 100px;
      font-size: smaller;
    }
    .logo{
      margin-left: -130px
    }
  }
  